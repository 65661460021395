import React, {createRef, MouseEvent, useEffect, useState} from "react";
import {RouteComponentProps} from "@reach/router";
import useDebounce from "@js/hook/useDebounce";
import {Helmet} from "react-helmet";
import Main from "@js/component/Main";
import Heading from "@js/component/Heading";
import Box from "@js/component/Box";
import AddressFormInputs from "@js/component/AddressFormInputs";
import Button from "@js/component/Button";

import * as classNames from "@css/component/template/BurdenOfProofCustomer.module.scss";
import Input from "@js/component/Input";
import CloudUploadIcon from "@js/component/icon/CloudUploadIcon";
import clsx from "clsx";

/**
 * @type BurdenOfProofCustomerProps
 */
type BurdenOfProofCustomerProps = RouteComponentProps & {
    hash?: string;
};

const BurdenOfProofCustomer = (props: BurdenOfProofCustomerProps) => {
    const {
        hash
    } = props;

    const debounce = useDebounce();
    const formRef = createRef<HTMLFormElement>();
    const [formHasErrors, setFormHasErrors] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [isSucceeded, setIsSucceeded] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const [street, setStreet] = useState("");
    const [houseNumber, setHouseNumber] = useState("");
    const [houseNumberSuffix, setHouseNumberSuffix] = useState("");
    const [city, setCity] = useState("");
    const [buildingOwnerZipCode, setBuildingOwnerZipCode] = useState("");

    const [errors, setErrors] = useState<{ [key: string]: string[]; }>({});
    const [uploadedFiles, setUploadedFiles] = useState();

    const onButtonClick = async (event: MouseEvent) => {
        event.preventDefault();

        if (!uploadedFiles || 0 === uploadedFiles.length) {
            return;
        }

        setIsSending(true);

        Array.from(uploadedFiles).map((file: File) => {
            const formData = new FormData();
            formData.append('file', file);

            fetch(`${ process.env.GATSBY_API_PARTNERSHIP_URL }/burden-of-proof/${hash}`, {
                    method: "POST",
                    body: formData
                }
            ).then((response) => {
                console.log("response.status =", response.status);
            }).then((data) => {
                console.log('success')
            }).catch((error) => {
                console.log("error =", error);
            });
        });

        setIsSending(false);
        setIsSucceeded(true);
    };

    const fetchAddressByHash = async () => {
        const response = await fetch(`${ process.env.GATSBY_API_PARTNERSHIP_URL }/burden-of-proof/${hash}/info`);

        const data = await response.json();

        // If we have a 404, no further action should be taken but returning the error message
        if(data.detail) {
            setStreet("");
            setHouseNumber("");
            setHouseNumberSuffix("");
            setCity("");
            setBuildingOwnerZipCode("");

            console.log(data.detail);
            return false;
        }

        setStreet(data.street);
        setHouseNumber(data.house_number);
        setHouseNumberSuffix(data.house_number_addition);
        setCity(data.city);
        setBuildingOwnerZipCode(data.postal_code);
    };

    useEffect(() => {
        void fetchAddressByHash();
    }, []);

    return (
        <Main className={classNames.main}>
            <Helmet>
                <title>Dien bewijslast in</title>
            </Helmet>
            <div className={classNames.burdenOfProof}>
                <div className={classNames.container}>
                    <div className={classNames.blocks}>
                        <div className={classNames.blockOne}>
                            <Heading className={classNames.mainHeading} variant="large">
                                Dien bewijslast in
                            </Heading>
                            { isSucceeded &&
                                <Box>
                                    <Heading className={ classNames.heading } element="h2" variant="medium">
                                        Je bewijslast is verzonden!
                                    </Heading>
                                    <p>
                                        Dank voor het aandragen van de bewijslast bij Woninglabel.nl.
                                        <br />
                                        <br />
                                        Voor vragen over onze dienstverlening als vergelijkingsplatform kun je
                                        ons altijd bereiken middels onze contactgegevens.
                                    </p>
                                </Box>
                            }
                            { !(isSucceeded) &&
                                <>
                                    <Box className={ classNames.box }>
                                        <Heading className={ classNames.heading } element="h2" variant="medium">
                                            Adresgegevens
                                        </Heading>
                                        <form method="POST" ref={ formRef }>
                                            <AddressFormInputs
                                                errors={ errors }
                                                initialZipCode={ buildingOwnerZipCode }
                                                inputZipCode={ buildingOwnerZipCode }
                                                onSetInputZipCode={
                                                    (event) => setBuildingOwnerZipCode(
                                                        event.currentTarget.value.toUpperCase()
                                                    ) }
                                                initialHouseNumber={ houseNumber }
                                                inputHouseNumber={ houseNumber }
                                                onSetInputHouseNumber={
                                                    (event) => setHouseNumber(event.currentTarget.value)
                                                }
                                                initialHouseNumberSuffix={ houseNumberSuffix }
                                                inputHouseNumberSuffix={ houseNumberSuffix }
                                                onSetInputHouseNumberSuffix={
                                                    (event) => setHouseNumberSuffix(event.currentTarget.value)
                                                }
                                                inputStreet={ street }
                                                onSetInputStreet={
                                                    (event) => setStreet(event.currentTarget.value)
                                                }
                                                inputCity={ city }
                                                onSetInputCity={
                                                    (event) => setCity(event.currentTarget.value)
                                                }
                                                buildingOwner={ true }
                                                disableAllFields={ true }
                                            />
                                            <Heading className={ classNames.heading } element="h2" variant="medium">
                                                <CloudUploadIcon className={ classNames.icon } />
                                                Voeg bestanden toe
                                            </Heading>
                                            <span className={ classNames.text }>Voeg in onderstaande uploadveld bestanden toe. Je kan meerdere bestanden tegelijk selecteren</span>
                                            <div className={ clsx(classNames.inputGroup, { [classNames.inputIsSending]: isSending }) }>
                                                <Input
                                                    id="proof"
                                                    name="proof"
                                                    type="file"
                                                    multiple
                                                    className={ classNames.fileInput }
                                                    hidden
                                                    onChange={ (event) => {
                                                        const files = event.target.files;
                                                        if (files) {
                                                            setUploadedFiles(files);
                                                        }
                                                    }}
                                                />
                                                <label htmlFor="proof" className={ classNames.button }>
                                                    <span className={ classNames.fileInputText }>Bestand(en) kiezen</span>
                                                </label>
                                                {uploadedFiles && uploadedFiles.length > 0 &&
                                                    <ol className={ classNames.fileList }>
                                                        {Array.from(uploadedFiles).map((file, index) => (
                                                            <li key={ index } className={ classNames.fileListItem }>
                                                                <span className={ classNames.fileListItemText }>{ file.name }</span>
                                                            </li>
                                                        ))}
                                                    </ol>
                                                }
                                                {isSending &&
                                                    <div className={classNames.searching}>
                                                        <span className={classNames.isSending}>Bestanden worden geüpload...</span>
                                                        <div className={classNames.searchSpinner}>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                        </div>
                                                    </div>
                                                }
                                        </div>
                                            { formHasErrors &&
                                                <div className={ classNames.notification }>
                                                    Er zijn één of meer velden niet ingevuld. Vul de ontbrekende gegevens aan en probeer het nog eens.
                                                </div>
                                            }
                                            <Button className={ classNames.button } disabled={ isSending } onClick={ onButtonClick } type="submit">
                                                <span className={ classNames.text }>Bewijslast versturen</span>
                                            </Button>
                                        </form>
                                    </Box>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Main>
);
}

export default BurdenOfProofCustomer;