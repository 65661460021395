// extracted by mini-css-extract-plugin
export var blockOne = "BurdenOfProofCustomer-module--blockOne--ZjEG4";
export var blocks = "BurdenOfProofCustomer-module--blocks--tdo8q";
export var box = "BurdenOfProofCustomer-module--box--cbTeK";
export var burdenOfProof = "BurdenOfProofCustomer-module--burdenOfProof--1fXY7";
export var button = "BurdenOfProofCustomer-module--button--hacnx";
export var container = "BurdenOfProofCustomer-module--container--KIkPn";
export var error = "BurdenOfProofCustomer-module--error--qdh+Y";
export var errors = "BurdenOfProofCustomer-module--errors--7-SXj";
export var fileInput = "BurdenOfProofCustomer-module--fileInput--xJqbL";
export var header = "BurdenOfProofCustomer-module--header--QEv9m";
export var heading = "BurdenOfProofCustomer-module--heading--2EmRe";
export var icon = "BurdenOfProofCustomer-module--icon--IPeYI";
export var inputGroup = "BurdenOfProofCustomer-module--inputGroup--pfV+c";
export var inputIsSending = "BurdenOfProofCustomer-module--inputIsSending--qUNdh";
export var isSending = "BurdenOfProofCustomer-module--isSending--jh3Hh";
export var main = "BurdenOfProofCustomer-module--main--wVA4g";
export var mainHeading = "BurdenOfProofCustomer-module--mainHeading--VcaC1";
export var notification = "BurdenOfProofCustomer-module--notification--ZSoEV";
export var searchSpinner = "BurdenOfProofCustomer-module--searchSpinner--GnIAx";
export var searching = "BurdenOfProofCustomer-module--searching--qkIEu";
export var text = "BurdenOfProofCustomer-module--text--B6GBA";