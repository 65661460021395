let id: number;

/**
 * @const useDebounce
 */
const useDebounce = () => {
    return (callback: () => void) => {
        window.clearTimeout(id);

        id = window.setTimeout(callback, 500);
    }
};

export default useDebounce;
